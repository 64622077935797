import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Button, Table, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import FormLoader from "../../../components/FormLoader";
import RegisterModal from "../../Student Landing/studentCareer/RegisterModal";
import { getLimitforFitRole, getUser } from "../../../utils/UserDetails";
import CareerService from "../../../services/Carrer.Service";
import AILayout from "../../../components/layout/AILayout";
import { getResumeRanking } from "../../../actions/recruiterAi";

const ResumeRanking = (props) => {
  let countClick =
    getLimitforFitRole()?.source === "resume-ranking" ? true : false;

  const [showModal, setShowModal] = useState(countClick);
  const [ipAddress, setIpAddress] = useState(
    localStorage.getItem("ip") ? localStorage.getItem("ip") : "",
  );

  const [resumeFiles, setResumeFiles] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [returnData, setReturnData] = useState({});
  const [folderError, setFolderError] = useState(null);
  const [FilterData, setFilterData] = useState([]);
  const [jdFileName, setJdFileName] = useState("");
  const initialData = {
    resume_folder: null,
    jd_file: null,
    profileId: 1,
  };

  useEffect(() => {
    if (props.resumeRankingData?.status === true) {
      setReturnData(props.resumeRankingData);
    }
  }, [props.resumeRankingData]);

  useEffect(() => {
    if (!getUser()) {
      const fetchIpAddress = async () => {
        try {
          const response = await fetch("https://api.ipify.org?format=json");

          const data = await response.json();
          setIpAddress(data.ip);
          localStorage.setItem("ip", JSON.stringify(data.ip));
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
      };

      if (ipAddress == "") {
        fetchIpAddress();
      }
    }
  }, []);

  const handleClose = () => setShowModal(false);

  const handleSubmit = async (data, setSubmitting, resetForm) => {
    const formData = new FormData();
    formData.append("profileId", 1);
    formData.append("jd_file", data.jd_file);
    for (const file of data.resume_folder) {
      formData.append("resume_folder", file);
    }

    if (getUser()) {
      props.getResumeRanking(formData, setSubmitting, resetForm);
    } else {
      let params = {
        ipaddress: ipAddress,
        source: "resume-ranking",
      };
      const resultData = await CareerService.VerifyLimit(params);

      if (true) {
        props.getResumeRanking(formData, setSubmitting, resetForm);
      } else {
        setShowModal(true);
        setSubmitting(false);
        resetForm();
      }
    }
  };

  const validFileExtensions = ["txt"];

  const isValidFileType = (fileName) => {
    return fileName && validFileExtensions.includes(fileName?.split(".").pop());
  };

  const validFolderExtensions = ["pdf", "doc"];

  const isValidFolderType = (fileName) => {
    return (
      fileName &&
      validFolderExtensions.includes(fileName.split(".").pop().toLowerCase())
    );
  };

  const filterValidFiles = (files) => {
    const validFiles = Array.from(files).filter((file) =>
      isValidFolderType(file.name),
    );
    return validFiles;
  };

  const validationSchema = Yup.object().shape({
    jd_file: Yup.mixed()
      .required("Please upload your job description")
      .test(
        "is-valid-type",
        "Only the following formats are accepted: .txt",
        (value) => isValidFileType(value?.name),
      ),
    resume_folder: Yup.mixed().required("Please upload your resume folder"),
  });

  const handleFileChange = (data) => {
    const fileArray = Object.values(resumeFiles);

    const filterData = fileArray?.filter(
      (item) => JSON.stringify(item.name) === JSON.stringify(data),
    );

    let file = filterData?.[0];

    if (file && file.type === "application/pdf") {
      const url = URL.createObjectURL(file);
      setPdfUrl(url);
    } else {
      toast.error("Please Try Again");
    }
  };

  return (
    <>
      <AILayout>
        <div className="container-xxl margin-top-80">
          <div className="">
            <h4
              className={`${props.activeColor} p-2 mb-3 text-center`}
              style={{ borderRadius: 6 }}
            >
              <b>Ranking Resume Based On JD (AI)</b>
            </h4>
          </div>
          {props.resumeRankingData &&
          props.resumeRankingData?.status === true ? (
            <Card className="p-3">
              <Row className="px-3">
                <Col md={6}>
                  <pre className="jd-overflow-css">{returnData?.jd_text}</pre>
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  {pdfUrl ? (
                    <iframe
                      src={pdfUrl}
                      title="Profile Preview"
                      width="100%"
                      height="300px"
                      style={{ border: "1px solid #ddd", marginTop: "15px" }}
                    />
                  ) : (
                    "Kindly Click the resume name in the below table"
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <Card className="p-3">
                    <div className="tableFixHead1">
                      <Table bordered striped>
                        <thead>
                          <tr>
                            <th>Resume</th>
                            <th>Keyword Matching (%)</th>
                            <th>Educational (%)</th>
                            <th>Relevant Experience (%)</th>
                            <th>Technical Skill (%)</th>
                            <th>Certification (%)</th>
                            <th>Gaps and Stability (%)</th>
                            <th>Final Score (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {returnData &&
                            returnData?.data?.map((data, index) => {
                              return (
                                <>
                                  <tr key={data?.resume_name}>
                                    <td
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleFileChange(data?.resume_name);
                                      }}
                                    >
                                      <b>{data?.resume_name}</b>
                                    </td>
                                    <td>
                                      <b>{data?.keyword_matching?.score}</b>
                                    </td>
                                    <td>
                                      <b>
                                        {
                                          data?.educational_qualifications
                                            ?.score
                                        }
                                      </b>
                                    </td>
                                    <td>
                                      <b>{data?.experience?.score}</b>
                                    </td>
                                    <td>
                                      <b>{data?.technical_skill?.score}</b>
                                    </td>
                                    <td>
                                      <b>{data?.certificate?.score}</b>
                                    </td>
                                    <td>
                                      <b>{data?.gaps_and_stability?.score}</b>
                                    </td>
                                    <td>
                                      <b>{data?.final_score}</b>
                                    </td>
                                  </tr>
                                  <tr key={data.resume_name}>
                                    <td>Comments</td>
                                    <td>{data?.keyword_matching?.comment}</td>
                                    <td>
                                      {
                                        data?.educational_qualifications
                                          ?.comment
                                      }
                                    </td>
                                    <td>{data?.experience?.comment}</td>
                                    <td>{data?.technical_skill?.comment}</td>
                                    <td>{data?.certificate?.comment}</td>
                                    <td>{data?.gaps_and_stability?.comment}</td>
                                    <td>{data?.summary_comment?.comment}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          ) : (
            <Card className="px-lg-5 px-md-5 py-5 my-3">
              <Formik
                initialValues={initialData}
                validationSchema={validationSchema}
                onSubmit={(formData, { setSubmitting, resetForm }) => {
                  handleSubmit(formData, setSubmitting, resetForm);
                  setFolderError(null);
                  setFilterData([]);
                  setJdFileName("");
                }}
                enableReinitialize={true}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <div className="d-flex justify-content-center align-items-center flex-column  mx-3 ">
                      <div className="d-flex flex-column align-items-center justify-content-around custom-input px-lg-5 px-md-3 px-sm-3">
                        <div className="mb-4">
                          <label
                            className={`btn btn-md p-4 px-4  profile-btn prfl-details-none btn-${props.activeColor}`}
                          >
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="text-white"
                              style={{ marginRight: "8px", fontSize: "14px" }}
                            />
                            <b
                              style={{ fontSize: "13px" }}
                              className="text-white"
                            >
                              Upload Resume Folder
                            </b>
                            <input
                              name="resume_folder"
                              type="file"
                              webkitdirectory="true"
                              className="d-none"
                              onChange={(event) => {
                                const files = event.currentTarget.files;
                                const filteredFiles = filterValidFiles(files);
                                setFilterData(filteredFiles);

                                if (filteredFiles.length > 0) {
                                  setFieldValue("resume_folder", filteredFiles);
                                  setResumeFiles(files);
                                  setFolderError(null);
                                } else {
                                  setFolderError(
                                    "Only the following formats are accepted: .pdf & .doc",
                                  );
                                }
                              }}
                              // onChange={(event) => {
                              //   setFieldValue(
                              //     "resume_folder",
                              //     event.currentTarget.files,
                              //   );
                              //   const files = Array.from(
                              //     event.currentTarget.files,
                              //   );
                              //   setResumeFiles(files);
                              // }}
                            />
                          </label>
                          <div className="err-height">
                            {FilterData?.length > 0 && folderError === null ? (
                              <>
                                <span className="selected-title">
                                  Selected Folder :{" "}
                                </span>
                                <span className="filename-display">
                                  {
                                    values.resume_folder?.[0]?.webkitRelativePath.split(
                                      "/",
                                    )[0]
                                  }
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            <span className="error-message">
                              {errors.resume_folder && !values.resume_folder
                                ? errors.resume_folder
                                : null}
                              {FilterData?.length === 0 && folderError
                                ? folderError
                                : null}
                            </span>
                          </div>
                        </div>

                        <div className="mb-4 ">
                          <label
                            className={`btn btn-md p-4 px-4 profile-btn prfl-details-none  ${
                              FilterData.length > 0
                                ? `btn-${props.activeColor}`
                                : "disabled-btn"
                            } `}
                            // disabled={uploadDisable}
                          >
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="text-white"
                              style={{ marginRight: "8px", fontSize: "14px" }}
                            />
                            <b
                              style={{ fontSize: "13px" }}
                              className="text-white"
                            >
                              Upload Job Description
                            </b>
                            <input
                              type="file"
                              accept=".txt"
                              name="jd_file"
                              className="d-none"
                              onChange={(event) => {
                                setFieldValue(
                                  "jd_file",
                                  event.currentTarget.files[0],
                                );
                                setJdFileName(
                                  event.currentTarget.files[0].name,
                                );
                              }}
                              disabled={FilterData.length > 0 ? false : true}
                            />
                          </label>
                          <div className="err-height">
                            <span className="filename-display">
                              {jdFileName}
                            </span>
                            <span className="error-message">
                              {errors.jd_file && touched.jd_file
                                ? errors.jd_file
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-row-reverse justify-content-center">
                          <Button
                            outline
                            type="submit"
                            color="primary btn-md m-2"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? <FormLoader /> : "Submit"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          )}
        </div>

        <Suspense fallback={""}>
          <RegisterModal
            show={showModal}
            handleClose={handleClose}
            userRole="RECRUITER"
          />
        </Suspense>
      </AILayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  resumeRankingData: state.recruiterAI.resumeRankingData,
});

export default connect(mapStateToProps, { getResumeRanking })(ResumeRanking);
