import ProfileService from "../services/profile.service";
import {
  TRAINING_EXPERIENCE,
  JOB_EXPERIENCE,
  QUALIFICATIONS,
  CERTIFICATIONS,
  AWARDS,
  SKILLS,
  RECORD_HIDE,
  SECTION_HIDE,
  DELETE,
  DESCRIPTION,
  CREATE_NEW_PROFILE,
  ENDORSE,
  ENDORSE_DETAILS,
  EDIT_PROFILE_MENU,
  SET_PRIMARY_PROFILE,
  ACTIVE_PROFILE,
  USER_PROFILES,
  CREATE_INTEREST,
  GET_INTERESTS,
  GET_PROFILE,
  UPLOAD_IMAGE,
  PROFILE_PREVIEW,
  ACTIVE_USER_PLAN,
  SET_SUSCRIPTION_PLAN,
  SET_SUSCRIPTION_DETAILS,
} from "./types";
import { toast } from "react-toastify";

export const getProfileDetails =
  (getAll = 0) =>
  async (dispatch) => {
    dispatch(addUserProfile([]));
    dispatch(setActiveProfile([]));
    const resultData = await ProfileService.getProfileDetails(getAll);

    if (resultData?.status && resultData?.status === true) {
      let activeProfile = ProfileService.getActiveProfile(
        resultData?.profileData,
      ).shift();
      dispatch(addUserProfile(resultData?.profileData));
      dispatch(setActiveProfile(activeProfile));
      dispatch(setActiveUserPlan(resultData?.userPlanData));
    }
  };

export const setPrimaryProfile = (activeProfile) => async (dispatch) => {
  const resultData = await ProfileService.setPrimaryProfile(
    activeProfile.user_profile_id,
  );

  if (resultData?.status && resultData.status === true) {
    dispatch({
      type: SET_PRIMARY_PROFILE,
      payload: {
        profileID: activeProfile.user_profile_id,
      },
    });
    dispatch(setActiveProfile(activeProfile));
  }
};

// to get all profiles
export const addUserProfile = (userProfiles) => {
  return {
    type: USER_PROFILES,
    userProfiles: userProfiles,
  };
};

// to set active profile
export const setActiveProfile = (profile) => {
  return {
    type: ACTIVE_PROFILE,
    activeProfile: profile,
  };
};

export const setActiveUserPlan = (profile) => {
  return {
    type: ACTIVE_USER_PLAN,
    activeUserPlan: profile,
  };
};

export const setProfilePreview = (profile) => {
  return {
    type: PROFILE_PREVIEW,
    profilepreview: profile,
  };
};

export const trainingExperience = (trainingData) => async (dispatch) => {
  try {
    const res = await ProfileService.trainingExperience(trainingData);

    if (res.expData.status) {
      let message = trainingData.userexperience_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: TRAINING_EXPERIENCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const jobExperience = (jobData) => async (dispatch) => {
  try {
    const res = await ProfileService.jobExperience(jobData);

    if (res.jobData.status) {
      let message = jobData.user_job_experience_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: JOB_EXPERIENCE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const qualification = (qualData) => async (dispatch) => {
  try {
    const res = await ProfileService.qualification(qualData);

    if (res.qualData.status) {
      let message = qualData.userqualification_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: QUALIFICATIONS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const certificate = (certificateData) => async (dispatch) => {
  try {
    const res = await ProfileService.certificate(certificateData);

    if (res.certData.status) {
      let message = certificateData.user_certificates_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: CERTIFICATIONS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const award = (awardData) => async (dispatch) => {
  try {
    const res = await ProfileService.award(awardData);

    if (res.awarData.status) {
      let message = awardData.user_awards_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: AWARDS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const skill = (skillData) => async (dispatch) => {
  try {
    const res = await ProfileService.skill(skillData);

    if (res.skilData.status) {
      let message = skillData.userskill_id ? "Updated" : "Added";
      toast.success(message);
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: SKILLS,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const description = (descriptionData) => async (dispatch) => {
  try {
    const res = await ProfileService.trainerDescription(descriptionData);

    dispatch({
      type: DESCRIPTION,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteRecord = (deleteData) => async (dispatch) => {
  try {
    const res = await ProfileService.deleteRecord(deleteData);

    if (res.enableData.status) {
      toast.success("Deleted");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: DELETE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const recordHide = (hideData) => async (dispatch) => {
  try {
    const res = await ProfileService.showAndHide(hideData);

    dispatch({
      type: RECORD_HIDE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createNewProfile =
  (profileData, key, profiles) => async (dispatch) => {
    try {
      const resultData = await ProfileService.newProfile(profileData);
      if (resultData?.status === true) {
        let message = profileData.user_profile_id
          ? "Profile Updated Successfully"
          : "Profile Added Successfully";
        toast.success(message);

        if (!profileData.user_profile_id) {
          profiles[key]["user_profile_id"] = resultData?.data;
        }

        dispatch({
          type: CREATE_NEW_PROFILE,
          payload: resultData,
        });
      } else {
        let message = resultData?.message;
        toast.error(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

export const sectionShow = (sectionData) => async (dispatch) => {
  try {
    const res = await ProfileService.sectionShow(sectionData);

    dispatch({
      type: SECTION_HIDE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const endorse = (endorseData, index, skill) => async (dispatch) => {
  try {
    const res = await ProfileService.endorse(endorseData);
    skill[index]["count"] = res.count;
    skill[index]["btnText"] = res.btnText;

    if (res.status) {
      toast.success("Endorsed!");
    } else {
      toast.success("Try again!");
    }

    dispatch({
      type: ENDORSE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEndorseDetails = (details) => async (dispatch) => {
  try {
    const res = await ProfileService.getEndorseDetails(details);

    dispatch({
      type: ENDORSE_DETAILS,
      payload: res.details,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editProfileMenu = (profileMenu) => async (dispatch) => {
  try {
    const res = await ProfileService.editProfileMenu(profileMenu);

    if (res.details.status) {
      toast.success("Updated!");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: EDIT_PROFILE_MENU,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addInterest = (interestData) => async (dispatch) => {
  try {
    const res = await ProfileService.createInterest(interestData);

    if (res.status) {
      toast.success("Added!");
    } else {
      toast.error("Try again");
    }

    dispatch({
      type: CREATE_INTEREST,
      payload: res.status ? res.profileData.data.interest : "",
    });
  } catch (err) {
    console.log(err);
  }
};

export const getInterests = () => async (dispatch) => {
  try {
    const res = await ProfileService.getInterests();
    dispatch({
      type: GET_INTERESTS,
      payload: res.details.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProfile = (profileData) => async (dispatch) => {
  try {
    const res = await ProfileService.getProfile(profileData);

    dispatch({
      type: GET_PROFILE,
      payload: res.details.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setImages = (data) => async (dispatch) => {
  try {
    const res = await ProfileService.uploadImage(data);

    dispatch({
      type: UPLOAD_IMAGE,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSubscription = (data) => async (dispatch) => {
  try {

    const resultData = await ProfileService.getSubscription(data);
   
    dispatch({
      type: SET_SUSCRIPTION_PLAN,
      payload: resultData,
    });
  } catch (err) {
    console.log(err);
  }
};

// export const getSuscriptionPlan = (data) => async (dispatch) => {
//   console.log("action");
//   console.log(data);
//   try {
//     // const resultData = await ProfileService.getSuscriptionPlan(data);
//     const resultData = true;

//     if (resultData) {
//       dispatch({
//         type: SET_SUSCRIPTION_PLAN,
//         payload: [
//           {
//             role: "student",
//             durations: [
//               {
//                 period: "quarterly",
//                 plans: [
//                   // { name: "Free", price: 0 },
//                   { name: "Growth", price: 299, status: "active" },
//                   { name: "Pro", price: 499 },
//                 ],
//               },
//               {
//                 period: "yearly",
//                 plans: [
//                   // { name: "Free", price: 0, status: "active" },
//                   { name: "Growth", price: 999, status: "active" },
//                   { name: "Pro", price: 1499 },
//                 ],
//               },
//               {
//                 period: "monthly",
//                 plans: [
//                   // { name: "Free", price: 0 },
//                   { name: "Growth", price: 129 },
//                   { name: "Pro", price: 199, status: "active" },
//                 ],
//               },
//             ],
//           },
//           {
//             role: "trainer",
//             durations: [
//               {
//                 period: "quarterly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Standard", price: 399 },
//                   { name: "Elegant", price: 599 },
//                 ],
//               },
//               {
//                 period: "yearly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Standard", price: 1299 },
//                   { name: "Elegant", price: 1999 },
//                 ],
//               },
//               {
//                 period: "monthly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Standard", price: 199 },
//                   { name: "Elegant", price: 299 },
//                 ],
//               },
//             ],
//           },
//           {
//             role: "recruiter",
//             durations: [
//               {
//                 period: "quarterly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Basic", price: 299 },
//                   { name: "Premium", price: 499 },
//                 ],
//               },
//               {
//                 period: "yearly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Basic", price: 999 },
//                   { name: "Premium", price: 1499 },
//                 ],
//               },
//               {
//                 period: "monthly",
//                 plans: [
//                   { name: "Free", price: 0, status: "active" },
//                   { name: "Basic", price: 129 },
//                   { name: "Premium", price: 199 },
//                 ],
//               },
//             ],
//           },
//         ],
//       });
//     }
//   } catch (err) {
//     console.log(err);
//     toast.error("Please Try Again");
//   }
// };

export const getSuscriptionDetails = () => async (dispatch) => {
  // console.log("action");
  // try {
  //   // const resultData = await ProfileService.getSuscriptionDetails();
  //   const resultData = true;
  //   if (resultData) {
  //     dispatch({
  //       type: SET_SUSCRIPTION_DETAILS,
  //       payload: [
  //         {
  //           membership_id: 1,
  //           membership_plan_name: "Growth",
  //           membership_plan_description: "Growth plan suitable for small teams",
  //           subscription_type_id: 1,
  //           role_id: 1,
  //           membership_price: "0.00",
  //           membership_validity_period: 20,
  //           membership_max_modules: 2,
  //           membership_max_like: 0,
  //           membership_max_reaction: "",
  //           membership_features: "Basic analytics, limited support",
  //           subscription_type: {
  //             subscription_type_id: 1,
  //             subscription_type_name: "free",
  //           },
  //           modules: [
  //             {
  //               module_id: 7,
  //               module_name: "Qboard",
  //               module_description: "Description for Qboard module",
  //               max_value: 10,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 10,
  //                   sub_module_name: "Global",
  //                   sub_module_description: "Global module for all users",
  //                   module_id: 7,
  //                   max_comment: "0",
  //                 },
  //                 {
  //                   sub_module_id: 11,
  //                   sub_module_name: "My Interest",
  //                   sub_module_description: "Tracks user interests",
  //                   module_id: 7,
  //                   max_comment: "50",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 9,
  //               module_name: "My Learning",
  //               module_description: "Description for My Learning module",
  //               max_value: 15,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 16,
  //                   sub_module_name: "Create Course",
  //                   sub_module_description: "Module for creating new courses",
  //                   module_id: 9,
  //                   max_comment: "20",
  //                 },
  //                 {
  //                   sub_module_id: 19,
  //                   sub_module_name: "My Career Road Map",
  //                   sub_module_description: "My Career Road Map",
  //                   module_id: 9,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 20,
  //                   sub_module_name: "Fit for this job",
  //                   sub_module_description: "Fit for this job",
  //                   module_id: 9,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 14,
  //               module_name: "History",
  //               module_description: "History",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 33,
  //                   sub_module_name: "People",
  //                   sub_module_description: "People",
  //                   module_id: 14,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 15,
  //               module_name: "Video Conferncing",
  //               module_description: "Video Conferncing",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 29,
  //                   sub_module_name: "zoom meet",
  //                   sub_module_description: "zoom meet",
  //                   module_id: 15,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 30,
  //                   sub_module_name: "zoint meet",
  //                   sub_module_description: "zoint meet",
  //                   module_id: 15,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 16,
  //               module_name: "My Space",
  //               module_description: "My Space",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 32,
  //                   sub_module_name: "onedrive",
  //                   sub_module_description: "onedrive",
  //                   module_id: 16,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           membership_id: 2,
  //           membership_plan_name: "Pro",
  //           membership_plan_description:
  //             "Professional plan with advanced features",
  //           subscription_type_id: 2,
  //           role_id: 1,
  //           membership_price: "99.90",
  //           membership_validity_period: 30,
  //           membership_max_modules: 3,
  //           membership_max_like: 0,
  //           membership_max_reaction: "",
  //           membership_features: "Advanced analytics, email support",
  //           subscription_type: {
  //             subscription_type_id: 2,
  //             subscription_type_name: "paid",
  //           },
  //           modules: [
  //             {
  //               module_id: 7,
  //               module_name: "Qboard",
  //               module_description: "Description for Qboard module",
  //               max_value: 10,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 10,
  //                   sub_module_name: "Global",
  //                   sub_module_description: "Global module for all users",
  //                   module_id: 7,
  //                   max_comment: "0",
  //                 },
  //                 {
  //                   sub_module_id: 11,
  //                   sub_module_name: "My Interest",
  //                   sub_module_description: "Tracks user interests",
  //                   module_id: 7,
  //                   max_comment: "50",
  //                 },
  //                 {
  //                   sub_module_id: 12,
  //                   sub_module_name: "My Connect",
  //                   sub_module_description: "Manages user connections",
  //                   module_id: 7,
  //                   max_comment: "0",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 9,
  //               module_name: "My Learning",
  //               module_description: "Description for My Learning module",
  //               max_value: 15,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 16,
  //                   sub_module_name: "Create Course",
  //                   sub_module_description: "Module for creating new courses",
  //                   module_id: 9,
  //                   max_comment: "20",
  //                 },
  //                 {
  //                   sub_module_id: 19,
  //                   sub_module_name: "My Career Road Map",
  //                   sub_module_description: "My Career Road Map",
  //                   module_id: 9,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 20,
  //                   sub_module_name: "Fit for this job",
  //                   sub_module_description: "Fit for this job",
  //                   module_id: 9,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 21,
  //                   sub_module_name: "CV Upload",
  //                   sub_module_description: "CV Upload",
  //                   module_id: 9,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 14,
  //               module_name: "History",
  //               module_description: "History",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 33,
  //                   sub_module_name: "People",
  //                   sub_module_description: "People",
  //                   module_id: 14,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 34,
  //                   sub_module_name: "Group for My Connect",
  //                   sub_module_description: "Group for My Connect",
  //                   module_id: 14,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 15,
  //               module_name: "Video Conferncing",
  //               module_description: "Video Conferncing",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 28,
  //                   sub_module_name: "google meet",
  //                   sub_module_description: "google meet",
  //                   module_id: 15,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 29,
  //                   sub_module_name: "zoom meet",
  //                   sub_module_description: "zoom meet",
  //                   module_id: 15,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 30,
  //                   sub_module_name: "zoint meet",
  //                   sub_module_description: "zoint meet",
  //                   module_id: 15,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //             {
  //               module_id: 16,
  //               module_name: "My Space",
  //               module_description: "My Space",
  //               max_value: 3,
  //               sub_modules: [
  //                 {
  //                   sub_module_id: 31,
  //                   sub_module_name: "google colud",
  //                   sub_module_description: "google colud",
  //                   module_id: 16,
  //                   max_comment: "3",
  //                 },
  //                 {
  //                   sub_module_id: 32,
  //                   sub_module_name: "onedrive",
  //                   sub_module_description: "onedrive",
  //                   module_id: 16,
  //                   max_comment: "3",
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     });
  //   }
  // } catch (err) {
  //   console.log(err);
  //   toast.error("Please Try Again");
  // }
};
