import { SET_SORTRESUME, RESUME_RANKING } from "../actions/types";

const initialState = {
  sortresume: null,
  resumeRankingData: {},
};

const RecruiterAIReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SORTRESUME: {
      return {
        ...state,
        sortresume: payload,
      };
    }
    case RESUME_RANKING: {
      return {
        ...state,
        resumeRankingData: payload,
      };
    }
    default:
      return state;
  }
};
export default RecruiterAIReducer;
