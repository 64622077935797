import {
  SET_DEVICEDATA,
  SET_FITFORROLE,
  SET_ROADMAP,
  VERIFY_LIMIT,
} from "../actions/types";

const initialState = {
  roadmap: null,
  devicedata: [],
  fitForRole: null,
  verifyLimitData: {},
};

const CarrerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ROADMAP: {
      return {
        ...state,
        roadmap: payload,
      };
    }
    case SET_FITFORROLE: {
      return {
        ...state,
        fitForRole: payload,
      };
    }
    case SET_DEVICEDATA: {
      return {
        ...state,
        devicedata: payload,
      };
    }

    case VERIFY_LIMIT: {
      return {
        ...state,
        verifyLimitData: payload,
      };
    }
    default:
      return state;
  }
};

export default CarrerReducer;
