import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faDesktop,
  faLaptop,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import TrainerHeader from "../../components/layout/TrainerHeader";
import CareerFooter from "../../components/layout/careerFooter";

const TrainerLandingPage = (props) => {
  const history = useHistory();
  const [hoveredCard, setHoveredCard] = useState(null);

  const caroseldata = [
    {
      title: "QBoard",
      icon: faQuestionCircle,
      content:
        "A platform to ask questions, share knowledge, and connect with fellow trainers. Post your queries, answer others, and collaborate to enhance your teaching skills and stay updated on industry trends.",
      profilePic: process.env.PUBLIC_URL + "/assets/img/career.webp",
    },
    {
      title: "Training",
      icon: faDesktop,
      content:
        "Share your expertise and expand your reach by posting your training offerings. Create detailed descriptions, set enrolment limits, and manage your course listings effortlessly from your trainer dashboard.",
      profilePic: process.env.PUBLIC_URL + "/assets/img/news-2.jpg",
    },
    {
      title: "Jobs",
      icon: faLaptop,
      content:
        "Discover a variety of teaching positions tailored to your expertise. Browse through available jobs, view details, and apply directly from your dashboard.",
      profilePic: process.env.PUBLIC_URL + "/assets/img/news-3.jpg",
    },
  ];

  const Testimonialsdata = [
    {
      name: "Katrina Peter",
      profile: "SoftSkill Trainer",
      content:
        "This platform has transformed how I deliver my courses. The tools available for creating engaging content and tracking student progress are invaluable. I can see my students thriving, which makes my job incredibly rewarding!",
      profilePic: process.env.PUBLIC_URL + "/assets/img/messages-1.jpg",
    },
    {
      name: "Suresh Shetty",
      profile: "Designer",
      content:
        "As a trainer, I appreciate the supportive community and resources this platform offers. It’s easy to connect with fellow educators and share best practices. The feedback from students helps me continually improve my courses.",
      profilePic: process.env.PUBLIC_URL + "/assets/img/messages-3.jpg",
    },
    {
      name: "Robert Mathew",
      profile: "Musician",
      content:
        "Using this platform has allowed me to reach a wider audience than ever before. The flexibility in scheduling and format means I can cater to diverse learning styles. I love seeing students succeed and grow through my training!",
      profilePic: process.env.PUBLIC_URL + "/assets/img/profile-img.jpg",
    },
  ];

  const handleCard = () => {
    history.push("/explore/trainer");
  };

  const handleCard1 = () => {
    history.push("/explore/trainer");
  };

  return (
    <>
      <TrainerHeader />
      <div className="trainer-hero-background">
        <div className="container" style={{ marginTop: 81 }}>
          <div className="row gy-4 title-container">
            <div
              className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate"
              data-aos="zoom-out"
            >
              <h1 className="title-text-trainer">
                Guide your students to success and achieve your goals.
              </h1>
              <p className="hero-content-text">
                Teach with purpose, share knowledge, and make a difference. By
                shaping the next generation, you'll lead with impact and inspire
                positive change that lasts.
              </p>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/trainer2.webp"}
                className="img-fluid animated"
                style={{ borderRadius: 20 }}
                alt="heroimage"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#cfe2f9" }}>
        <div className="container">
          <div className="course-container">
            <h2 className="course-container-title">Delve Deeper</h2>
            <div className="row gy-5 justify-content-center h-100">
              <div className="col-xl-4 col-md-6">
                <div className="service-item">
                  <div className="img">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/news-5.jpg"}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-easel"></i>
                    </div>
                    {/* <a href="" className="stretched-link"> */}
                    <h3 className="service-details-title">
                      Create Training Curriculam(AI)
                    </h3>
                    {/* </a> */}
                    <p className="service-details-content">
                      Your centralized hub for creating, managing, and tracking
                      your training courses. Develop engaging lesson plans,
                      organize course materials, and monitor student progress to
                      ensure a seamless and effective learning experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="service-item">
                  <div className="img">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/news-1.jpg"}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-bounding-box-circles"></i>
                    </div>
                    <a className="stretched-link">
                      <h3 className="service-details-title">
                        Create Training(AI)
                      </h3>
                    </a>
                    <p className="service-details-content ">
                      Your platform to design and deliver engaging course
                      materials. Develop interactive lessons, quizzes, and
                      assignments to captivate your students and facilitate
                      their learning.
                    </p>
                    <a className="stretched-link"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#27293d" }}>
        <div className="container">
          <div className="course-container">
            <div className="explore-more-container">
              <h3 className="explore-more-title1">
                Discover More About TJunction
              </h3>
              <div className="d-flex row">
                {caroseldata.map((item, index) => {
                  return (
                    <div
                      className={`${
                        hoveredCard == index ? "tjunc-section-css" : ""
                      } col-md-4 col-sm-12 col-12 col-lg-4`}
                    >
                      <div
                        className="d-flex flex-column my-3"
                        key={index}
                        onMouseEnter={() => setHoveredCard(index)}
                        onMouseLeave={() => setHoveredCard(null)}
                      >
                        <div
                          className={`${
                            hoveredCard == index
                              ? "tjunc-icon-container-hvr"
                              : ""
                          } tjunc-icon-container`}
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            className={`${
                              hoveredCard == index ? "tjunc-icon-css-hvr" : ""
                            } text-muted tjunc-icon-css`}
                          />
                        </div>
                        <div>
                          <h3
                            className={`${
                              hoveredCard == index ? "tjunc-card-title-hvr" : ""
                            } tjunc-card-title`}
                          >
                            {item.title}
                          </h3>
                          <hr
                            className={`${
                              hoveredCard == index ? "tjunc-hr-css-hvr" : ""
                            } tjunc-hr-css`}
                          />
                          <p
                            className={`${
                              hoveredCard == index ? "tjunc-card-text-hvr" : ""
                            } tjunc-card-text`}
                          >
                            {item.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "rgb(255 255 255)" }}>
        <div className="container">
          <div className="testimonial-container">
            <h2 className="test-container-title1">Here Our Testimonials</h2>
            <div className="d-flex row justify-content-between">
              {Testimonialsdata.map((item, key) => {
                return (
                  <div className="col-md-3 col-sm-12 col-12 col-lg-3 my-2 testimonial-sec-css">
                    <img
                      src={item.profilePic}
                      className="tjunc-image-css"
                      alt="img"
                    />
                    <h5 className="testimonial-card-title">{item.name}</h5>
                    <p className="testimonial-card-title1">{item.profile}</p>
                    <p className="testimonial-card-content">{item.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#09325b" }}>
        <div className="container">
          <CareerFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, null)(TrainerLandingPage);
