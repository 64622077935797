import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";

const PurchaseConfirmModal = ({
  isOpen,
  setIsOpen,
  selectedPlan,
  Duration,
  handlePayment,
  activeColor,
  loading,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal"
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1 text-center">
          Confirm Purchase
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {selectedPlan ? (
          <>
            <p>
              You have selected the <strong>{selectedPlan.name}</strong> plan.
            </p>
            <p>
              Duration:{" "}
              <strong>
                {Duration.charAt(0).toUpperCase() + Duration.slice(1)}
              </strong>
            </p>
            <p>
              Price: <strong>₹{selectedPlan.price}</strong>
            </p>
          </>
        ) : (
          <p>No plan selected.</p>
        )}
      </Modal.Body>
      <Modal.Footer className=" justify-content-center ">
        <Button
          type="button"
          className={`my-1 px-4 py-1 btn-${activeColor}`}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className={`my-1 px-4 py-1 btn-${activeColor}`}
          onClick={() => handlePayment()}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Ok"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {})(PurchaseConfirmModal);
