import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "font-awesome/css/font-awesome.min.css";
import "./fonts/OpenSans/OpenSans-regular.ttf";
import "./fonts/PTSans/PTSans-Regular.ttf";
import "./App.css";
import "./styles/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-intl-tel-input/dist/main.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import { configureStore } from "./store/configureStore";
import { Provider } from "react-redux";
import App from "./App";

const store = configureStore();
const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("app"));
    hasRendered = true;
  }
};

renderApp();
