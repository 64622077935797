import RequirementService from "../services/requirement.service";
import { getUser } from "../utils/UserDetails";
import {
  SET_JOB_POST,
  JOB_POST_COUNT,
  UPDATE_JOB,
  JOB_ERROR,
  JOB_SUCCESS,
  INSERT_JOB,
  DELETE_JOB_POST,
  SET_JOB_CATEGORY,
  SET_JOB_SPECIALIZATION,
  SET_JOB_APPLY,
  JOB_APPLY_DETAILS,
  JOB_AI_DETAILS,
  RESET_JOB_AI_DETAILS,
} from "./types";

export const getJobPost =
  (jobRequestData, setIsLoading, isFromFilter) => async (dispatch) => {
    try {
      let jobData = {
        isCreation: isFromFilter,
      };

      let jobPostCount = 0;

      const resultData = await RequirementService.getJobPosts(jobRequestData);

      if (resultData?.posts && !resultData.posts.errCode) {
        setIsLoading(false);
        jobData.jobPost = resultData.posts.collection;
        jobPostCount = resultData.posts.post_count;
      } else {
        dispatch(setJobError("Please Try Again!"));
      }

      dispatch({
        type: SET_JOB_POST,
        payload: jobData,
      });

      dispatch({
        type: JOB_POST_COUNT,
        payload: jobPostCount,
      });
    } catch (err) {
      dispatch(setJobError("Please Try Again!"));
    }
  };

export const getRequiredJobData = () => async (dispatch) => {
  try {
    const resultData = await RequirementService.getRequiredJobData();

    if (resultData?.status && resultData.status === true) {
      dispatch({
        type: SET_JOB_CATEGORY,
        payload: resultData?.job_categories ? resultData.job_categories : [],
      });

      dispatch({
        type: SET_JOB_SPECIALIZATION,
        payload: resultData?.job_specialization
          ? resultData.job_specialization
          : [],
      });
    }
  } catch (err) {
    dispatch(setJobError("Please Try Again!"));
  }
};

export const jobPostData = (data) => async (dispatch) => {
  let notifyMsg = "Posted";
  try {
    const resultData = await RequirementService.postJobData(data);

    if (resultData.errCode === 0) {
      dispatch(setJobSuccess(notifyMsg));
      dispatch({
        type: JOB_AI_DETAILS,
        payload: {},
      });
    } else {
      dispatch(setJobError("Please Try Again!"));
    }
  } catch (err) {
    dispatch(setJobError("Please Try Again!"));
  }
};

export const applyJob =
  (jobPost, resetForm, setSubmitting, closeModal) => async (dispatch) => {
    try {
      const resultData = await RequirementService.postJobApply(jobPost);
      if (resultData.errCode === 0) {
        closeModal();
        resetForm();
        dispatch(
          updateJobPost({
            requirements_job_id: jobPost.requirements_job_apply_requirements_id,
            isApplied: 1,
          }),
        );
        dispatch(setJobSuccess("Applied !"));
      } else {
        dispatch(setJobError("Please Try Again!"));
        setSubmitting(false);
      }
    } catch (err) {
      dispatch(setJobError("Please Try Again!"));
    }
  };

export const deleteJobPost =
  (deletePostData, setShowDeleteLoader, closeConfirmationModal) =>
  async (dispatch) => {
    try {
      const resultData = await RequirementService.postJobDelete(
        deletePostData.requirements_job_id,
      );
      if (resultData.errCode === 0) {
        setShowDeleteLoader(false);
        closeConfirmationModal();
        dispatch(setJobSuccess("Deleted"));
        dispatch({
          type: DELETE_JOB_POST,
          payload: deletePostData,
        });
      }
    } catch (err) {
      setShowDeleteLoader(false);
      dispatch(setJobError("Please Try Again!"));
    }
  };

export const showJobApplyDetails = (requirementID) => async (dispatch) => {
  try {
    const resultData = await RequirementService.jobApplicationDetails(
      requirementID,
    );

    if (resultData.errCode === 0) {
      let data = {
        isModalOpen: true,
        appliedDetailsData: resultData.data,
      };
      dispatch(setJobApplyDetails(data));
    }
  } catch (err) {
    dispatch(setJobError("Please Try Again!"));
  }
};

export const jobApplyresumeDownload = (jobApplyID) => async () => {
  await RequirementService.getAppliedResume(jobApplyID);
};

export const setJobApplyData = (jobApplyData) => async (dispatch) => {
  dispatch({
    type: SET_JOB_APPLY,
    payload: jobApplyData,
  });
};

export const updateJobPost = (updatedData) => {
  return {
    type: UPDATE_JOB,
    payload: updatedData,
  };
};

export const insertJobPost = (updatedData) => {
  return {
    type: INSERT_JOB,
    payload: updatedData,
  };
};

export const setJobApplyDetails = (data) => {
  return {
    type: JOB_APPLY_DETAILS,
    payload: data,
  };
};

export const setJobSuccess = (messages = "") => {
  return {
    type: JOB_SUCCESS,
    payload: messages,
  };
};

export const setJobError = (messages = "") => {
  return {
    type: JOB_ERROR,
    payload: messages,
  };
};

export const getJobAIDetails =
  (data, setSubmitting, closeModal) => async (dispatch) => {
    try {
      const resultData = await RequirementService.getJobAIDetails(data);
      if (resultData.status) {
        if (!getUser()) {
          localStorage.setItem(
            "limitEnchanceJD",
            JSON.stringify({
              source: "limitEnchanceJD",
              limit: 2,
            }),
          );
        }
      }
      setSubmitting(false);

      dispatch({
        type: JOB_AI_DETAILS,
        payload: resultData,
      });

      closeModal();
    } catch (err) {
      dispatch(setJobError("Please Try Again!"));
    }
  };

export const resetJobAIDetails = () => {
  return {
    type: RESET_JOB_AI_DETAILS,
  };
};
